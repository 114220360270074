<template>
    <div class="project-layout" :class="{ 'map-view-container': viewType === 'map' }">
        <el-scrollbar class="list-view-projects-container" :class="{ 'tile-view-projects-container': viewType === 'tile' && windowWidth > 768 || viewType === 'map' && windowWidth > 768, 'mobile-container': windowWidth <= 768 }">
            <DisplayTypeComponentMobile @set-view="setView"/>
            <el-card @click="routeToProjectDetailsPage(item)" v-show="item.status === status || status === 'All'" v-for="(item, index) in data" :key="index" :class="{ 'tile-card': viewType === 'tile' || viewType === 'map' || windowWidth <= 768 }">
                <div class="project-layout__column-one">
                    <ProjectItemThumbnail :project="item"/>
                    <div class="project-label-container">
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="item.name"
                            placement="top"
                        >
                        <p class="project-title">{{item.name}}</p>
                        </el-tooltip>
                        <p class="project-postcode"><span><el-icon :size="10" color="#0C0F4A"><LocationFilled /></el-icon></span>{{ item.postcode ? item.postcode : 'n/a' }}</p>
                    </div>
                </div>
                <div class="project-layout__column-two" :class="{ 'grid-col-span-2': viewType === 'tile' || viewType === 'map' || windowWidth <= 768 }">
                    <div class="status-boxes-container">
                        <div class="box">
                            <p class="detail">{{ $filters.formatToDatetime(item?.dateCreated, 'DD/MM/YYYY') }}</p>
                            <p class="label">Posted</p>
                        </div>
                        <div class="box">
                            <p class="detail">{{item?.projectJobStatus?.display}}</p>
                            <p class="label"> Status</p>
                        </div>
                        <div class="box">
                            <p class="detail">{{ item?.projectMembers?.length }}</p>
                            <p class="label">Members</p>
                        </div>
                    </div>
                    <el-steps v-if="item.status === 'In progress' || item.status === 'Not started'" :active="item.status === 'Not started' ? 0 : item.progressStatus" finish-status="success">
                        <el-step/>
                        <el-step/>
                        <el-step/>
                        <el-step/>
                    </el-steps>
                </div>
                <div class="project-layout__column-three" :class="{ 'tile-column-three': viewType === 'tile' || viewType === 'map' || windowWidth <= 768 }">
                    <el-icon class="more" :size="20" color="#0C0F4A;"><MoreFilled/></el-icon>
                    <el-icon v-if="isWithArrowPreview" @click="viewFilePreview" class="arrow" :size="17" color="rgba(12, 15, 74, 0.5)"><ArrowRightBold/></el-icon>
                </div>
            </el-card>
        </el-scrollbar>
        <!--THIS WHERE THE MAP WILL BE PUT-->
        <div class="map-container" v-if="viewType === 'map'">
            <el-image
                class="attachment-image"
                fit="cover"
                :src="require('@/assets/images/sample-map-image.jpg')"
            >
                <template v-slot:error>
                <div class="image-slot">
                    <el-icon><Picture /></el-icon>
                </div>
                </template>
            </el-image>
        </div>
    </div>
</template>
<script>
import {
  ArrowRightBold,
  LocationFilled,
  MoreFilled,
  Picture
}
  from '@element-plus/icons';
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import DisplayTypeComponentMobile from '@/modules/project-search/components/DisplayTypeComponentMobile.vue';
import ProjectItemThumbnail from '@/modules/property-details-children/components/ProjectItemThumbnail.vue';

export default defineComponent({
  components: {
    ArrowRightBold,
    LocationFilled,
    MoreFilled,
    Picture,
    DisplayTypeComponentMobile,
    ProjectItemThumbnail
  },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  emits: ['set-window-width'],

  props: ['type', 'isWithArrowPreview', 'data', 'status'],
  setup(props, { emit }) {
    const initialCount = ref(8);
    /* eslint-disable global-require */
    const url = ref(`${require('@/assets/images/po-project-empty-placeholder.jpg')}`);
    const srcList = ref([
      `${require('@/assets/images/sample-image.jpg')}`,
    ]);
    /* eslint-disable global-require */
    const router = useRouter();

    const viewType = computed(() => {
      return props.type;
    });

    function viewFilePreview() {
      emit('view-preview', true);
    }

    function routeToProjectDetailsPage(project) {
      router.push(`/projects/${project.refId}/project-details/overview`);
    }

    return {
      initialCount,
      url,
      srcList,
      viewType,
      viewFilePreview,
      routeToProjectDetailsPage
    };
  },

  watch: {
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
      this.$emit('set-window-width', newWidth);
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

:deep(.el-scrollbar__bar) {
    display: block !important;
}
:deep(.el-scrollbar__bar.is-vertical) {
    top: 0;
    right: 0;
    width: 6px !important;
}
   .map-view-container {
       display:flex;
       gap: 2.2rem;
   }
   .project-layout {
    //   height: 100%;
    // height: calc(100% - 4rem);
    height: calc(100% - 4.2rem);
    // height: calc(100% - 8.6rem);
   }
   .el-scrollbar.list-view-projects-container * p {
       margin: 0;
       color: #0C0F4A;
       font-family: Mulish;
       text-align: left;
   }

  .el-scrollbar.list-view-projects-container {
    :deep(.el-scrollbar__view) {
        display: grid;
        grid-template-columns:1fr;
        gap: 1.5rem;
        height: auto !important;
        padding-bottom: 2rem;
        padding-right: .7rem;
    }

    .el-card {
        // height: 150px;
        border-radius: 16px;
        cursor: pointer;
        outline: none;
        border:none;

        :deep(.el-card__body) {
            padding: 1rem;
            display: grid;
            grid-template-columns: 2.5fr 3fr .3fr;
            gap: 1rem;
        }

        .project-layout__column-one {
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            .el-image {
                width: 126px;
                height: 126px;
                border-radius: 10px;
                min-width: 126px;
            }
            .project-label-container {
                display:flex;
                flex-direction: column;
                gap: .2rem;
                .project-title {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: 0.25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    line-height: 1.2em;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .project-address {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.25px;
                    opacity: 0.5;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    line-height: 1.2em;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .project-postcode {
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.25px;
                    opacity: 0.5;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: .5rem;
                    span {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }
            }
        }
        .project-layout__column-two {
            display:flex;
            flex-direction: column;
            gap: 1.5rem;
            margin-right:.5rem;
            align-items: center;
            justify-content: center;

            .status-boxes-container {
                display:grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 1.2rem;
                height: 59px;
                width: 100%;

                .box {
                    background: rgba(12, 15, 74, 0.05);
                    border-radius: 8px;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    display:flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: .7rem;
                    gap: .4rem;

                    .detail {
                        font-size: 12px;
                        font-weight: 700;
                        // line-height: 25px;
                        letter-spacing: 0.25px;
                        text-align: center;
                    }
                    .label {
                        font-size: 8px;
                        font-weight: 400;
                        // line-height: 25px;
                        letter-spacing: 0.25px;
                        text-align: center;
                        opacity: 0.7;
                    }
                }
            }

            .el-steps {
                width: 100%;

                :deep(.el-step__head.is-success) {
                    color: #fff;
                    border-color: #FFA500;
                    .el-step__line {
                        height: 3px;
                        background-color:  #FFA500;
                    }
                    .el-step__icon.is-text {
                        background-color: #FFA500;
                    }
                }
                :deep(.el-step__head.is-process) {
                    color: #FFA500;
                    border-color: #FFA500;
                    .el-step__line {
                        background-color:  rgba(12, 15, 74, 0.05);
                    }
                    .el-step__icon.is-text {
                        background-color: rgba(12, 15, 74, 0.05);
                        border: 3px solid;
                        .el-step__icon-inner {
                            font-size: 10px;
                            font-weight: 700;
                            line-height: 25px;
                            letter-spacing: 0.25px;
                            text-align: center;
                        }
                    }
                }
                :deep(.el-step__head.is-wait) {
                    color: rgba(12, 15, 74, 0.5);
                    border-color: rgba(12, 15, 74, 0.05);
                    .el-step__line {
                        // height: 3px;
                        background-color:  rgba(12, 15, 74, 0.05);
                    }
                    .el-step__icon.is-text {
                        background-color: rgba(12, 15, 74, 0.05);
                        border: none;
                        .el-step__icon-inner {
                            font-size: 10px;
                            font-weight: 700;
                            line-height: 25px;
                            letter-spacing: 0.25px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .project-layout__column-three {
            display:flex;
            flex-direction: column;
            gap: 2.2rem;

            .more {
                text-align: right;
                width: 100%;
            }
            .arrow {
                cursor: pointer;
            }
        }
    }

    .el-card.is-always-shadow {
      box-shadow: none;
    }
  }
  .el-scrollbar.tile-view-projects-container {
      :deep(.el-scrollbar__view) {
        grid-template-columns: repeat(auto-fill, minmax(300px,1fr));
      }

    .el-card.tile-card {
        :deep(.el-card__body) {
            padding: 1rem;
            padding-bottom: 2rem;
            display: grid;
            grid-template-columns: 4fr .5fr;
            gap: 1rem;
        }
        .grid-col-span-2 {
            grid-column: span 2;
        }
        .tile-column-three {
            grid-column-start: 2;
            grid-row-start: 1;
            justify-content: space-between;
            align-items: flex-end ;
            display:flex;
            flex-direction: column;

            .more {
                text-align: right;
                width: 100%;
            }
        }
    }
  }
  .el-scrollbar.mobile-container {
      :deep(.el-scrollbar__view) {
      }

    .el-card.tile-card {
        :deep(.el-card__body) {
            padding: 1rem;
            padding-bottom: 2rem;
            display: grid;
            grid-template-columns: 4fr .5fr;
            gap: 1rem;
        }
        .grid-col-span-2 {
            grid-column: span 2;
        }
        .tile-column-three {
            grid-column-start: 2;
            grid-row-start: 1;
            justify-content: space-between;
            align-items: flex-end ;
            display:flex;
            flex-direction: column;

            .more {
                text-align: right;
                width: 100%;
            }
        }
    }
  }

  .map-container {
      overflow-y: scroll;
      height: calc(100% - 4.5rem);
      border-radius: 16px;
      .el-image {
        border-radius: 16px;
      }
  }

@include media-md-max-width() {
    .project-layout {
      //    height: calc(100% - 9.3rem);
      height: calc(100% - 3.3rem);
    }
}

@media screen and (max-width: 800px) {
    .project-layout {
        // height: calc(100% - 7.1rem);
        height: calc(100% - 4.1rem);
    }
}
@include media-xs-max-width() {
    .default-project-view-container {
       height: calc(100% - 3.3rem);
    }
    .box {
        padding: 0.7rem 0.5rem !important;
        justify-content: center !important;
        gap: .4rem !important;
    }
    .detail {
        font-size: 10px !important;
    }
}
</style>
