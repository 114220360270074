<template>
    <div class="no-content-container">
        <inline-svg class="cta-icon" :src="require(`@/assets/icons/empty-project-icon.svg`)"></inline-svg>
        <p class="message">There are no project here</p>
        <el-button
            @click="routeToAddProject"
            type="primary"
            class="white-button"
            round
            :loading="submitting"
            >Start new project
        </el-button>
    </div>
</template>
<script>
// import { useRouter } from 'vue-router';
import { mapGetters } from 'vuex';

import { PROJECT_POST_ROUTE } from '../../project-post/routes';

export default {
  // setup() {
  //   const router = useRouter();

  //   function routeToAddPropertyForm() {
  //     router.push({
  //       name: 'select-property',
  //     });
  //   }

  //   return {
  //     routeToAddPropertyForm
  //   };
  // },
  computed: {
    ...mapGetters(['isTradesperson', 'isProjectOwner']),
  },
  methods: {
    routeToAddProject() {
      if (this.isProjectOwner) {
        const { id } = this.$route.params;
        if (id) {
          this.$router.push({ name: PROJECT_POST_ROUTE, params: { propertyId: id } });
        } else {
          this.$router.push({ name: 'select-property' });
        }
      } else if (this.isTradesperson) {
        this.$router.push({ name: 'find-jobs' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.no-content-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;

    .message {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.4000000059604645px;
        color: rgba(12, 15, 74, 0.5);
        text-align:center;
    }

    .white-button {
        background: #FFF;
        border-radius: 7px;
        color: #4F55F0;
        outline: none;
        border: none;
        width: 176px;
        height: 42px;
        box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.1);
    }
}
</style>
